.infographicContainer {
  height: 100%;
}
svg.infographic {
  width: 100%;
  height: 100%;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  text {
    fill: $darkgreen;
    stroke: none;
  }
  g.map-nl {
    path {
      fill: $mapgrey;
    }
  }
  g.relations {
    path {
      stroke: rgba($darkgreen, 0.4);
      stroke-dasharray: 1 4;
      fill: none;
      stroke-width: 1;
    }
  }
  g.experimenteerGebieden {
    circle {
      cursor: pointer;
      &.area {
        fill: rgba($darkgreen, 0.2);
      }
      &.centerpoint {
        fill: $darkgreen;
      }
    }
  }
  g.nodes {
    .node {
      cursor: pointer;
      .symbol {
        transform: translate(0, -6px) scale(1);

        fill: $darkgreen;
        &.zoomed {
          transform: translate(-2px, -12px) scale(2);
        }
      }
      text {
        // font-weight: 700;

        .click-indicator {
          font-weight: 700;
        }
        .clickaction {
          font-weight: 400;
          font-size: 10px;
        }
        .primarycontact {
          font-weight: 700;
        }
      }

      &.experiment {
        .symbol {
          transform: translate(-2px, -8px) scale(1.5);

          fill: transparent;
          stroke: $darkgreen;
          &.zoomed {
            transform: translate(-2px, -12px) scale(2);
          }
        }
      }
      &.gremium {
        .symbol {
          fill: transparent;
          stroke: $darkgreen;
        }
      }
    }
  }
  g.directies {
    .directie {
      fill: rgba($darkgreen, 0.5);
      font-weight: 700;
      font-size: 1.2em;
    }
  }
  g.kkl {
    cursor: pointer;
    g.kklarea {
      text {
        fill: rgba($darkgreen, 0.7);
        font-weight: 700;
        text-transform: uppercase;
      }
      .symbol {
        fill: $kkl;
      }
    }
  }

  g.ontwikkeltrajecten {
    cursor: pointer;
    g.ontwikkeltraject {
      text {
        fill: rgba($darkgreen, 0.7);
        font-weight: 700;
        text-transform: uppercase;
      }
      &.pilotswr {
        .symbol {
          fill: $pilots;
        }
      }
      &.truepricing {
        .symbol {
          fill: $true-pricing;
        }
      }
      &.kpik {
        .symbol {
          fill: $kpi;
        }
      }
      &.samenwerkingakkvee {
        .symbol {
          fill: $akkerbouw-veehouderij;
        }
      }
      &.toekomstbeelden2050 {
        .symbol {
          fill: $visie-2050;
        }
      }
      &.lerenencommuniceren {
        .symbol {
          fill: $leren-en-communiceren;
        }
      }
    }
  }
  g.ontwikkelbadges {
    .badge {
      .badgetitle {
        font-weight: 700;
        text-transform: uppercase;
        fill: rgba($darkgreen, 0.5);
      }
      &.pilotswr {
        fill: $pilots;
      }
      &.truepricing {
        fill: $true-pricing;
      }
      &.kpik {
        fill: $kpi;
      }
      &.samenwerkingakkvee {
        fill: $akkerbouw-veehouderij;
      }
      &.toekomstbeelden2050 {
        fill: $visie-2050;
      }
      &.lerenencommuniceren {
        fill: $leren-en-communiceren;
      }
    }
  }
}
