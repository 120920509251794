header {
  background-color: #fff;
  display: flex !important;

  .logo {
    width: 3em;
    margin-left: 5em;
    cursor: pointer;
    img {
      width: 100%;
      //   padding: 0 1em;
    }
  }
  h1 {
    font-size: 1.8em;
    cursor: pointer;
    color: $darkgreen;
    margin-left: 1em;
    flex-grow: 100;
    span {
      margin-left: 0.2em;
      font-weight: normal;
    }
  }
  .loginButton {
    justify-self: flex-end;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 0 1em;
    cursor: pointer;
  }
}
main {
  .ui {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    pointer-events: none;
    display: flex;
    -webkit-user-select: none; /* Chrome all / Safari all */
    -moz-user-select: none; /* Firefox all */
    -ms-user-select: none; /* IE 10+ */
    user-select: none; /* Likely future */
    .edit {
      pointer-events: all;
      cursor: pointer;
    }
    > div {
      position: relative;
      // border: 2px solid red;
      width: 50%;
      margin: 50px 50px 50px 50px;
      // padding: 20px;

      h2 {
        color: rgba($darkgreen, 0.4);
        margin-top: 0;
        font-size: 2rem;
        vertical-align: middle;
        .arrow {
          vertical-align: middle;
          margin-right: 0.4em;
          transform: scale(0.85);
          transform-origin: left top;
        }
        &.active {
          pointer-events: all;
          cursor: pointer;
        }
      }
      // &.right {
      //   h1 {
      //     position: relative;
      //     top: 40%;
      //     left: 50%;
      //   }
      // }
      h1 {
        .icon {
          pointer-events: all;
          cursor: pointer;
        }
      }
      .description {
        display: flex;
        pointer-events: all;
        > div {
          width: 80%;
          padding-left: 3.2em;
          &.edit {
            padding-left: 1em;
            width: 30%;
          }
        }
        .edit {
          pointer-events: all;
          opacity: 0;
        }
        &:hover {
          .edit {
            opacity: 1;
          }
        }
      }

      .form {
        pointer-events: all;
        height: auto;
        * {
          pointer-events: all;
        }
        .field {
          .button {
            height: auto;
            margin-left: 1em;
            width: auto;
          }
        }
      }
      &.left {
        padding-left: 100px;
        h1 {
          margin-left: 0.7em;
          pointer-events: all;
          cursor: pointer;
          svg {
            margin-right: 1em;
            transform-origin: center;
            transform: scale(3);
          }
          &.pilotswr {
            path {
              fill: $pilots;
            }
          }
          &.truepricing {
            path {
              fill: $true-pricing;
            }
          }
          &.kpik {
            path {
              fill: $kpi;
            }
          }
          &.samenwerkingakkvee {
            path {
              fill: $akkerbouw-veehouderij;
            }
          }
          &.toekomstbeelden2050 {
            path {
              fill: $visie-2050;
            }
          }
          &.lerenencommuniceren {
            path {
              fill: $leren-en-communiceren;
            }
          }
          &.teamexperimenteergebiedenkll,
          &.teamexperimenteergebiedenkkl,
          &.kkl {
            path {
              fill: $kkl;
            }
          }
        }
      }
      &.right {
        .ui-line {
          border: 1px solid rgba(#1a424f, 0.3);

          position: absolute;
          top: -1em;
          right: 1em;
          width: calc(100% + 1em);
          height: calc(100% + 1em);
          transform-origin: center right;
          svg {
            width: 100%;
            height: 100%;
            path {
              fill: none;
              stroke: #1a424f;
              stroke-width: 1;
              stroke-dasharray: 2000, 2000;
            }
          }
          // background: rgba(#fff, 0.3);

          // transform: scale(1.1);
        }
        &.editmode {
          background: rgba($backgroundColor, 0.9);
        }
        .edit {
          margin-left: 0.2em;
        }
        .editbar {
          margin-top: 1em;
          * {
            pointer-events: all;
            cursor: pointer;
          }
        }
        .badges {
          display: flex;
          width: 60%;
          flex-wrap: wrap;
          &.edit {
            display: block;
            * {
              pointer-events: all;
            }
            > div {
              margin: 2em 0;
              display: flex;
              label {
                color: $darkgreen;
                @extend .bold;
              }
            }
            .editbar {
              > div {
                padding: 1em;
              }
            }
          }

          .field {
            margin-right: 0.5em;
          }
          .badge {
            pointer-events: all;
            cursor: pointer;
            margin-bottom: 0.5em;
            svg {
              width: 1em;
              margin-right: 0.25em;
            }
            &.pilotswr {
              path {
                fill: $pilots;
              }
            }
            &.truepricing {
              path {
                fill: $true-pricing;
              }
            }
            &.kpik {
              path {
                fill: $kpi;
              }
            }
            &.samenwerkingakkvee {
              path {
                fill: $akkerbouw-veehouderij;
              }
            }
            &.toekomstbeelden2050 {
              path {
                fill: $visie-2050;
              }
            }
            &.lerenencommuniceren {
              path {
                fill: $leren-en-communiceren;
              }
            }
            &.teamexperimenteergebiedenkll,
            &.teamexperimenteergebiedenkkl,
            &.kkl {
              path {
                fill: $kkl;
              }
            }

            margin-right: 0.7em;
            display: flex;
            align-items: center;
          }
        }
      }
      .button {
        cursor: pointer;
        pointer-events: all;
        vertical-align: middle;
        img {
          vertical-align: middle;
          margin-right: 0.5em;
        }
      }
      .buttonGroupBottom {
        position: absolute;
        bottom: 0px;
        right: 0px;
      }
    }
  }
}
footer {
  height: 5rem;
  background-color: #fff;
}
