$timelineGrey: #ebebeb;
.timeline {
  background-color: #fff;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 2em;
  .label {
    flex-shrink: 10;
    margin-right: 1em;
    @extend .bold;
    font-size: 1.2em;
  }
  .timeline-drag-constraint {
    flex-grow: 10;
    border-radius: 20px;

    margin: auto 3em;
    background-color: $timelineGrey;
    height: 2px;
  }

  .dragger {
    border-radius: 0.5em;
    // margin: auto;
    // height: 10px;
    // width: 10px;
    width: 2em;
    height: 2em;
    top: -1em;
    left: -1em;

    position: relative;
    cursor: grab;
    &:active {
      cursor: grabbing;
    }
    img {
      width: 100%;
      transition: 0.2s ease-in;
      pointer-events: none;
    }
    &:hover {
      img {
        transform: scale(1.2);
      }
    }
  }
}
