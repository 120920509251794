$paddingModalSides: 5rem;
$paddingBetweenSections: 2rem;
#modalContainer {
  .dimmer {
    position: fixed;
    background-color: rgba(0, 0, 0, 0.5);
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    .modal {
      position: relative;
      border-radius: 1em;
      background-color: #fff;
      padding: $paddingModalSides/2 0;
      width: 55vw;
      min-height: 50vh;

      .closeButton {
        position: absolute;
        top: 1em;
        right: 1em;
        cursor: pointer;
      }
      .scrollable {
        max-height: calc(50vh);
        overflow: auto;
      }
      section {
        position: relative;

        padding: 0 $paddingModalSides $paddingBetweenSections $paddingModalSides;
        // margin-bottom: 3em;
        &.divider {
          height: 1px;
          padding: 0;
          margin: 0 $paddingModalSides $paddingBetweenSections;
          background-color: $lightgrey;
        }
        &.withDivider {
          // border-top: 1px solid $lightgrey;
          padding-top: 3em;
        }
        h6 {
          color: $grey;
          font-size: 0.9em;
          margin: 1em 0;
          @extend .regular;
        }
        .columns {
          display: flex;
          justify-content: space-between;
          flex-wrap: wrap;
          &.nowrap {
            flex-wrap: nowrap;
          }
          &.list {
            justify-content: flex-start;
            margin-top: 1em;
          }
        }
        &.themes {
          .inlinecolumns {
            display: flex;
            justify-content: flex-start;
            align-items: flex-start;
          }
          .theme {
            width: 25%;
          }
        }
        &.relations {
          .columns {
            // justify-content: flex-start;
          }
          .personCard,
          .card {
            width: 33%;
            font-size: 0.6em;
            padding-bottom: 2em;

            cursor: pointer;
            .icon {
              img {
                width: 2em;
              }
            }
            h2 {
              font-size: 1.5em;
            }
          }
          .personSelect {
            width: 33%;
            .checkbox {
              width: 100%;
              &:hover {
                background-color: rgba(0, 0, 0, 0.1);
              }
            }
          }
        }
        &.visibility {
          > div:not(.editBar) {
            justify-content: flex-start;
            &.visibilityEdit {
              justify-content: space-between;
              flex-wrap: nowrap;
              > div {
                margin-right: 1em;
                &.datepickers {
                  width: 100%;
                  display: flex;
                  .datepickerContainer {
                    transition: 0.2s;
                    margin-right: 1em;

                    &.disabled {
                      opacity: 0.2;
                    }
                  }
                }
              }
            }
          }
        }
        .editBar {
          display: flex;
          position: relative;
          justify-content: flex-end;
          align-items: flex-end;
          margin-top: 1em;
          // top: 2.5em;
          // right: 0.5em;
          // border: 2px solid red;
          height: 3em;
          opacity: 0;
          transition: 0.2s;
          overflow: visible !important;
          &.dontHide {
            opacity: 1;
          }
          &.alignLeft {
            justify-content: flex-start;
          }
        }
        &:hover,
        &.editing {
          .editBar {
            opacity: 1;
          }
        }
      }
      .removeBar {
        font-size: 0.8em;
        .button {
          cursor: pointer;
          margin: 1em;
        }
        &.disabled {
          opacity: 0.5;
        }
      }

      .tag {
        border: 1px solid $darkgreen;
        border-radius: 1.5em;
        color: $darkgreen;
        padding: 0.5em 2em;
        margin-left: 1em;
        margin-bottom: 1em;
        display: inline-block;
      }

      .personCard,
      .card,
      .ontwikkeltrajectCard {
        display: flex;
        align-items: center;
        width: 70%;

        > div {
          width: 100%;

          &.icon {
            flex-shrink: 100;
            img {
              margin-right: 1em;
              width: 5em;
            }
          }
          h1 {
            font-size: 2em;
            margin: 0;
          }
          h2 {
            font-size: 1.2em;
            margin: 0;
            @extend .regular;
          }
          .form {
            .fields {
              margin-bottom: 0 !important;
            }
          }
        }
      }
      .ontwikkeltrajectCard {
        .icon {
          margin-right: 1em;
          svg {
            width: 4em;
            height: 4em;
          }
          &.pilotswr {
            path {
              fill: $pilots;
            }
          }
          &.truepricing {
            path {
              fill: $true-pricing;
            }
          }
          &.kpik {
            path {
              fill: $kpi;
            }
          }
          &.samenwerkingakkvee {
            path {
              fill: $akkerbouw-veehouderij;
            }
          }
          &.toekomstbeelden2050 {
            path {
              fill: $visie-2050;
            }
          }
          &.lerenencommuniceren {
            path {
              fill: $leren-en-communiceren;
            }
          }
          &.teamexperimenteergebiedenkll,
          &.teamexperimenteergebiedenkkl,
          &.kkl {
            path {
              fill: $kkl;
            }
          }
        }
      }

      .logbookContainer {
        position: relative;
        .timeline-line {
          width: 1px;
          height: calc(100% - 2em);

          position: absolute;
          left: 7.5vw;
          top: 1em;
          border-left: 1px dashed $grey;
        }
        &.editmode {
          .timeline-line {
            top: 7em;
            height: calc(100% - 9em);
          }
        }
        table.logbook {
          width: 100%;
          // font-size: 1.5rem;
          tr {
            td {
              vertical-align: top;
              padding-bottom: 2em;
              .editBar {
                .field {
                  margin-bottom: 0;
                }
              }
              &:first-of-type {
                width: 5vw;
                text-align: center;
              }
              &.date {
                font-size: 1.6em;
                -webkit-user-select: none;
                -moz-user-select: none;
                -ms-user-select: none;
                user-select: none;
                .year {
                  font-size: 1.45em;
                  display: inline-block;
                  margin-top: 0.2em;
                }
              }
              &:nth-of-type(2) {
                width: 5vw;
                text-align: center;
              }
              .timelineSpot {
                background: $lightgrey;
                width: 1em;
                height: 1em;
                border-radius: 50%;
                display: inline-block;
                transform: translateX(-25%);
              }
            }
            .edit {
              font-size: 1rem;
              > i {
                font-size: 0.8em;

                cursor: pointer;
              }
            }
            &:last-of-type {
              td:nth-of-type(2) {
                position: relative;
                background: #fff;
              }
            }
          }
        }
      }
    }
  }
  .dashboardScreen_dimmer {
    position: fixed;
    background-color: rgba(0, 0, 0, 0.8);
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    .dashboardScreen {
      padding: 10em;
      background-color: $backgroundColor;
      border-radius: 1em;
      position: relative;
      .header {
        display: block;
        color: $darkgreen;
      }
      ul {
        list-style: none;
        padding: 0;
        li {
          padding: 0.2em;
          cursor: pointer;
        }
      }
      .close {
        cursor: pointer;
        position: absolute;
        top: 1.5em;
        right: 1em;
        font-size: 1.2em;
      }
    }
  }
}
