@import url("https://fonts.googleapis.com/css2?family=Assistant:wght@400;700&display=swap");

* {
  box-sizing: border-box;
}
$darkgreen: #085456;
$grey: #929090;
$lightgrey: #d7d7d7;
$backgroundColor: #deece9;
$mapgrey: rgba(#757575, 0.15);

$true-pricing: #36b7ff;
$pilots: #fe7342;
$kpi: #fff89e;
$akkerbouw-veehouderij: #00cc99;
$visie-2050: #765449;
$leren-en-communiceren: #0038ff;
$kkl: #b0ac4b;
html,
body {
  height: 100%;
  margin: 0;
  font-family: "Assistant", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: $darkgreen;

  font-size: calc(9px + 0.3vh);
}
h1,
h2,
h3,
h4,
h5,
h6 {
  color: $darkgreen;
  font-family: "Assistant", sans-serif;
}

.regular {
  font-weight: 400;
}
.bold {
  font-weight: 700;
}
.emptyText {
  color: $lightgrey;
}
/* screen sizing */
.app {
  height: 100vh;
  background-color: $backgroundColor;
}
.timeline {
  height: 3em;
}

header {
  height: 6em;
}

main {
  height: calc(100% - 6em - 3em);
  position: relative;
}

.smallScreenMessage {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.8);
  justify-content: center;
  align-items: center;
  color: #fff;
  pointer-events: none;
  display: flex;
  opacity: 0;
  transition: 0.2s;
  h1,
  p {
    color: inherit;
  }
  p {
    font-size: 1.2em;
  }
  h1 {
    .icon {
      margin-right: 0.5em;
    }
  }
  @media (max-width: 1000px), (max-height: 550px) {
    opacity: 1;
    pointer-events: all !important;
  }
}
