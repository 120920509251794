.introScreen {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  > div {
    position: relative;
    width: 25%;
    margin: 0 3em;
    &:last-of-type {
      &:after {
        content: "";
        background-color: $darkgreen;
        width: 1px;
        position: absolute;
        top: 0;
        height: 100%;
        left: -3em;
      }
    }
  }
}
